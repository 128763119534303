<template>
<div>
  <template>
    <div id="breadcrumb-alignment" class="mb-1">
      <div class="d-flex justify-content-start breadcrumb-wrapper">
        <b-breadcrumb
          :items="breadCrumb()"
        />
      </div>
    </div>
  </template>
  <b-row class="match-height">
    <b-col
      lg="12"
      md="12"
    >
   
    <b-card
      title="Custom Forms Validation Setting">
      <b-alert
        v-model="showDismissibleAlert"
        variant="danger"
        dismissible
        class="mb-1"
      >
        <div class="alert-body">
          {{error_message}}
        </div>
      </b-alert>
      
      <b-form @submit="formSubmit">
        
        <b-row>
          
          <b-col md="12">

            <!-- <b-row class="mb-1">
              <b-col md="3">
                <b-form-group
                  label="Form Name"
                  class="mb-0"
                >
                </b-form-group>

              </b-col>

              <b-col md="3">
                <b-form-group
                  label="Validate (Level 1)"
                  class="mb-0" 
                > 
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group
                  label="Validate (Level 2)"
                  class="mb-0" 
                > 
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group
                  label="Validate (Level 3)"
                  class="mb-0" 
                > 
                </b-form-group>
              </b-col>

            </b-row>
            <hr> -->

            <!-- <b-row v-for="(scf,index) in items" :key="index" class="mb-1" v-if="items.length > 0 && scf.form.title != 'Lactation Checklist'">

              <b-col md="3" class="mb-1">
                
                {{ scf.form.title}}
              </b-col>


              <b-col md="3" class="mb-1">
                
                <b-form-select v-model="scf.validateLevelOne">

                  <b-form-select-option value='' disabled>Select</b-form-select-option>
                  
                  <b-form-select-option :value="role.role" v-for="role in roles" :key="role._id + index">{{role.name}}</b-form-select-option>
                  <b-form-select-option value='crew' v-if="scf.form.title == 'Warning Letter'" >Crew</b-form-select-option>
                  
                </b-form-select>
              </b-col>

              <b-col md="3" class="mb-1">

                <b-form-select v-model="scf.validateLevelTwo">

                  <b-form-select-option value='' disabled>Select</b-form-select-option>
                  
                  <b-form-select-option :value="role.role" v-for="role in roles" :key="role._id + index + 1000">{{role.name}}</b-form-select-option>
                  <b-form-select-option value='crew' v-if="scf.form.title == 'Warning Letter'" >Crew</b-form-select-option>
                </b-form-select>
                
              </b-col>

              <b-col md="3" class="mb-1">

                <b-form-select v-model="scf.validateLevelThree" :disabled="checkDisableLevel3(scf.form.title)">

                  <b-form-select-option value='' disabled>Select</b-form-select-option>
                  
                  <b-form-select-option :value="role.role" v-for="role in roles" :key="1000 + role._id + index">{{role.name}}</b-form-select-option>
                  <b-form-select-option value='crew' v-if="scf.form.title == 'Warning Letter'" >Crew</b-form-select-option>
                </b-form-select>
                
              </b-col>


            </b-row>

            <b-row class="mb-1" v-if="items.length == 0">
              <b-col md="12">
                Custom Forms not found
              </b-col>

            </b-row> -->
            
            
            <b-table 
                responsive
                show-empty
                ref = "refUserListTable"
                class = "position-relative"
                primary-key = "id"
                empty-text = "No matching records found"
                :fields = "tableColumns"
                :items = "items"
            >

                <template #cell(validateLevelOne)="items">
                  <b-form-select v-model="items.item.validateLevelOne">
                    <b-form-select-option value='' disabled>Select</b-form-select-option>
                    <b-form-select-option :value="role.role" v-for="(role, index) in roles" :key="role._id + index">{{role.name}}</b-form-select-option>
                    <b-form-select-option value='crew' v-if="items.item.form.title == 'Warning Letter'" >Crew</b-form-select-option>
                  </b-form-select>
                </template>
                
                <template #cell(validateLevelTwo)="items">
                  <b-form-select v-model="items.item.validateLevelTwo">
                    <b-form-select-option value='' disabled>Select</b-form-select-option>
                    <b-form-select-option :value="role.role" v-for="(role, index) in roles" :key="role._id + index">{{role.name}}</b-form-select-option>
                    <b-form-select-option value='crew' v-if="items.item.form.title == 'Warning Letter'" >Crew</b-form-select-option>
                  </b-form-select>
                </template>
                
                <template #cell(validateLevelThree)="items">
                  <b-form-select v-model="items.item.validateLevelThree">
                    <b-form-select-option value='' disabled>Select</b-form-select-option>
                    <b-form-select-option :value="role.role" v-for="(role, index) in roles" :key="role._id + index">{{role.name}}</b-form-select-option>
                    <b-form-select-option value='crew' v-if="items.item.form.title == 'Warning Letter'" >Crew</b-form-select-option>
                  </b-form-select>
                </template>

            </b-table>

            <hr>
            
            <b-row>
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="warning"
                  class="mr-1"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="$router.push({ name:'custom-form-settings' })"
                >
                  Cancel
                </b-button>
              </b-col>
          </b-row>


          </b-col>
        </b-row>

      </b-form>
    </b-card>
    </b-col>
  </b-row>

  
</div>
</template>

<script>

import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio, BBreadcrumb
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
export default {
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BFormText,
    BInputGroupPrepend,
    BInputGroup,
    BMedia,
    BAvatar,
    BTable,
    BModal,
    VueCropper,
    BFormRadio,
    BBreadcrumb
  },
  directives: {
    Ripple,
  },
  data() {
    return {

      
      error_message:null,
      showDismissibleAlert:false,
      
      items :[],
      roles:[],

      tableColumns: [
					{ key: 'form.title', label: 'Form Name', sortable: false , thStyle:  {width: '25%'}},
					{ key: 'validateLevelOne', label: 'Validate (Level 1)', sortable: false , thStyle:  {width: '25%'}},
					{ key: 'validateLevelTwo', label: 'Validate (Level 2)', sortable: false , thStyle:  {width: '25%'}},
					{ key: 'validateLevelThree', label: 'Validate (Level 3)', sortable: false , thStyle:  {width: '25%'}}
      ],
      
    }
  },
  methods : {
    formSubmit(e){
      e.preventDefault();

      return this.$store.dispatch(POST_API, {
           data:{
             items:this.items
           },
           api: '/api/update-custom-forms-validation'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Setting Updated Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    // this.$router.go(-1);
                    this.$router.push({ name:'custom-form-settings' })
                });
                
            }
        });
      
    },
    getRoles(){
      return this.$store.dispatch(POST_API, {
           data:{
             //role:this.$store.getters.currentUser.role
           },
           api: '/api/get-roles'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;

                this.roles = this.$store.getters.getResults.data;
                
                return this.roles;
            }
        }); 
    },
    organizationCustomForms(){
      return this.$store.dispatch(POST_API, {
           data:{
             id:this.$store.getters.currentUser.organization,
             role:this.$store.getters.currentUser.role,
           },
           api: '/api/client-custom-forms'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;

                this.items = this.$store.getters.getResults.data;

                return this.items;
            }
        });
    },
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:{name:'custom-forms'},
        text: 'Custom Forms',
      },{
        to:{name:'custom-form-settings'},
        text:'Form Settings',
      },{
        to:null,
        text: 'Validation Settings',
        active:true
      }];
      return item;
    },
    checkDisableLevel3(title){
      var arr = [/*'Toilet Inspection Checklist',*/'Quality & Safety Benchmark Assessments'];
      if (arr.indexOf(title) >= 0) {
        return true;
      }else{
        return false;
      }
    }

  },
  mounted(){
    this.getRoles();
    this.organizationCustomForms();
  }
}
</script>
